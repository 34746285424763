import en from 'locales/en';
import es from 'locales/es';
import pt from 'locales/pt';
import { LangObjDictionary } from 'types/language';
import { LANGUAGE } from '../constants';
import { getItem } from '@lib';

const defaultLangObj = en;
const languages: LangObjDictionary = { en: en, es: es, pt: pt };

const useLocale = () => {
  const locale = getItem(LANGUAGE) ?? 'en';

  if (process.env.NODE_ENV === 'test') return defaultLangObj;
  // // TODO load language dynamically from the server
  if (locale in languages) return languages[locale];
  return defaultLangObj;
};
export default useLocale;
