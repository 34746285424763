export default {
  auth: {
    login: {
      email: {
        title: 'Digite seu e-mail',
        input: {
          label: 'Digite seu e-mail',
          description:
            'Forneça um e-mail que usaremos para nos comunicar com você, incluindo todas as informações de reservas.',
          placeholder: 'youremail@gmail.com',
        },
      },
      passwordText:
        'Para prosseguir, enviaremos a você uma senha única por e-mail.',
      btnLogin: 'Continuar',
      error: {
        emailFormat: 'Formato de e-mail incorreto, tente novamente',
        emailError:
          'Digite o e-mail correto ou ligue para o suporte porque essa conta já está vinculada a outro e-mail',
      },
    },
    checkCode: {
      title: 'Verifique seu e-mail',
      description: 'Enviamos um código exclusivo para ',
      wrongEmailTitle: 'E-mail incorreto?',
      reEnterEmailLabel: 'Digite novamente seu e-mail',
      passcodeSubtitle: 'Senha única',
      uniqueCodeText:
        'Você pode reenviar o código exclusivo em {count} segundos',
      btn: {
        resend: 'Reenviar',
        verify: 'Verificar',
      },
      error: {
        wrongCode:
          'A senha pode estar incorreta ou ter expirado. Por favor, tente novamente.',
      },
    },
    signUp: {
      title: 'Atualizar dados',
      subtitle: 'Atualize seus dados abaixo',
      firstNameInput: {
        label: 'Nome(s)',
        placeholder: 'John',
      },
      lastNameInput: {
        label: 'Sobrenome(s)',
        placeholder: 'Smith',
      },
      emailInput: {
        label: 'E-mail',
      },
      marketingAgreementText:
        'Aceito receber e-mails de marketing personalizados.',
      signUpButton: 'Fazer login',
      validationError: {
        invalidEmail: 'Digite um endereço de e-mail válido.',
        maxLength: 'No máximo 255 caracteres',
        emptyName: 'O nome não pode ficar vazio',
      },
      error: {
        signUpError: 'Ocorreu um erro',
        attempts: {
          title: 'Muitas tentativas incorretas',
          description:
            '5 tentativas incorretas foram feitas para digitar o código de verificação. Tente novamente em uma hora',
          btn: {
            reEnter: 'Digite novamente o e-mail',
            contact: 'Entre em contato com o suporte',
          },
        },
      },
    },
    maintenance: {
      title: 'Manutenção em andamento',
      description:
        'O serviço de reservas está indisponível temporariamente porque uma atualização do sistema está sendo executada. Volte mais tarde ou entre em contato com o suporte.',
      note: 'Volte mais tarde ou',
      btn: {
        support: 'Entre em contato com o suporte',
      },
    },
    notFound: {
      title: 'Página não encontrada',
      description:
        'A página que você procura pode ter sido removida ou está temporariamente indisponível.',
      descriptionMobile: 'Tente novamente ou pesquise outras opções.',
      btn: {
        returnToLounge: 'RETORNAR À SALA VIP',
        support: 'Entre em contato com o suporte',
      },
    },
  },
  lounge: {
    perPerson: 'por pessoa',
    errors: {
      server: {
        title: 'O serviço está indisponível temporariamente',
        description:
          'Pode ter ocorrido um erro no sistema. Tente novamente ou pesquise outras opções.',
        btn: {
          return: 'Voltar para Salas',
        },
      },
      capacity: {
        title: 'A sala VIP atingiu sua capacidade máxima',
        description: {
          known:
            'A sala VIP selecionada atingiu sua capacidade máxima no momento. Tente reservar para um número menor de convidados ou veja se há outra sala VIP disponível.',
          notKnown:
            'Pedimos desculpas, mas a capacidade da sala VIP para o horário que você selecionou é de 2 adultos e 1 criança.',
        },
        solutions: {
          title: 'Você pode',
          points: [
            'Alterar o número de convidados',
            'Alterar os horários',
            'Encontrar outra sala VIP',
          ],
        },
        btn: {
          change: 'Alterar o número de convidados',
          return: 'Retornar às salas VIP',
        },
      },
      unavailable: {
        title: 'A sala VIP está indisponível',
        description:
          'Não foi possível atender à sua solicitação para reservar a sala VIP no momento. Pedimos desculpas pela inconveniência causada. Tente novamente mais tarde ou entre em contato com o suporte ao cliente para obter ajuda. Agradecemos a sua compreensão.',
      },
      terminalsMismatch: {
        title: 'Terminais incompatíveis',
        description:
          'Observe que a sala VIP que você está reservando não fica no terminal em que seu voo está programado. O terminal da sala VIP é o Terminal Norte. O terminal de partida do voo é o Terminal Sul. Você ainda quer reservar esta sala VIP, mesmo ela não estando no terminal de partida?',
        btn: {
          return: 'Retornar às salas VIP',
          continue: 'Continuar com a reserva',
        },
      },
    },
    topBarLinks: {
      backToLounge: 'VOLTAR PARA ',
      faqs: 'Perguntas frequentes',
      back: 'VOLTAR',
    },
  },
  booking: {
    flightAndGuests: {
      title: 'Voo e entrada de convidados',
      amendTitle: 'Alterações na reserva',
    },
    flightDetails: {
      title: 'Dados do meu voo',
      localeValue: 'en',
      dateInput: {
        label: 'Data',
        placeholder: 'Data do voo',
      },
      numberInput: {
        label: 'Número do voo',
        placeholder: 'Por exemplo: EZY123',
      },
      time: {
        label: 'Horário do voo',
      },
      errors: {
        invalid_date: 'É preciso informar a data do voo.',
        invalid_dateflight:
          'Informações do voo não reconhecidas. Verifique e tente novamente.',
        invalid_flight:
          'Informações do voo não reconhecidas. Verifique e tente novamente.',
      },
    },
    guestDetails: {
      title: 'Quem vem?',
      description:
        'O tamanho máximo do grupo é {guests}, excluindo bebês. Verifique a disponibilidade para restrições específicas da sala VIP sobre o número de bebês.',
      adultsInput: {
        label: 'Adultos',
        description: 'Idade superior a 12 anos',
      },
      childrenInput: {
        label: 'Crianças',
        description: 'De 2 a 11 anos',
      },
      infantsInput: {
        label: 'Bebês',
        description: 'De 0 a 2 anos',
      },
      loungeTerms: {
        line1: 'Consultar ',
        link: 'condições da sala VIP',
        line2: 'para restrições de idade',
      },
      errors: {
        capacity:
          'A capacidade máxima da sala VIP é um total de {guests} convidados. Alterar o número de convidados.',
      },
    },
    availableSlots: {
      title: 'Horário estimado de chegada',
      description:
        'Os horários são mostrados no fuso horário da localização da sala VIP',
      placeholder: 'Selecionar horário',
      arrivalDescription: 'Esse é o horário em que você chegará à sala VIP.',
      stayTime: {
        line1: 'Visto que seu voo é às ',
        line2: ', sua permanência máxima é ',
        line3: '3 horas antes',
      },
      totalPrice: {
        title: 'Preço total',
        titleAmend: 'Preço adicional a pagar',
        titleRefund: 'Valor a receber',
        samePrice: 'Nenhuma taxa adicional é necessária',
      },
      panelInfoHeader: {
        date: 'Data',
        flightTime: 'Horário do voo',
        flightNumber: 'Número do voo',
      },
      busynessIndicator: {
        title: 'Estará movimentada?',
        lowestLabel: 'Improvável',
        highestLabel: 'Muito provável',
        defaultTooltipMessage:
          'O horário de pico pode afetar a capacidade. Selecione o horário de chegada para obter informações sobre a capacidade da sala VIP',
        tooltipMessage: [
          'Geralmente, a sala VIP não fica perto da capacidade total',
          'Esta sala VIP geralmente tem ocupação baixa.',
          'Esta sala VIP pode ficar moderadamente movimentada',
          'Esta sala VIP pode ficar perto da capacidade total. Faça a reserva antecipada da sua visita para garantir seu lugar.',
          'Esta sala VIP pode estar na capacidade total. Faça a reserva antecipada da sua visita para garantir seu lugar',
        ],
        errorTooltipMessage:
          'As informações da capacidade da sala VIP estão indisponíveis no momento',
        messageOnError:
          ' As informações da capacidade da sala VIP estão indisponíveis no momento. Faça a reserva antecipada da visita para garantir seu lugar.',
        messageBeforeSelected:
          'Selecione o horário de chegada para obter informações sobre a capacidade',
      },
      hsbcCancelationPolicy: {
        title:
          'Observe que uma taxa de reserva antecipada aplica-se para titulares do cartão HSBC',
        descriptionLine1:
          'Para titulares do cartão HSBC Premier, essa taxa de reserva antecipada será adicionada às taxas de passes de visita suas e de seu(s) convidado(s). Ao chegar à sala VIP, você ainda terá que pagar £ 24 por visitante.',
        descriptionLine2:
          'Para titulares do cartão HSBC Premier World Elite, observe que ainda que você receba passes para visitas grátis para você e titulares do cartão adicionais (ou seja, não seus convidados) a taxa de reserva antecipada será uma taxa separada e também se aplica a você e aos seus convidados. Quando',
      },
      cancellationPolicy: {
        title: 'Política de cancelamento',
        descriptionLine1:
          'Cancele até 48 horas antes da sua reserva para receber o reembolso integral. As reservas não podem ser canceladas dentro de 48 horas do horário de chegada, incluindo novas reservas feitas dentro desse intervalo de tempo.',
        descriptionLine2:
          'Confirme se os dados estão corretos antes de fazer o pagamento.',
      },
      btn: 'CONFIRMAR',
      amendBtn: 'PROSSEGUIR COM ALTERAÇÕES',
      errors: {
        airportMismatch: {
          title: 'Os aeroportos não correspondem',
          description:
            'A sala VIP que você está reservando não é no mesmo aeroporto do qual o seu voo está programado para sair.',
        },
        terminalMismatch: {
          title: 'Os terminais não correspondem',
          description:
            'A sala VIP que você está reservando não é no mesmo terminal do qual o seu voo está programado para sair.',
          continueBtn: 'CONTINUAR COM A RESERVA',
        },
        noAmendChanges: {
          title: 'Selecione outros detalhes',
          description:
            'Você não fez nenhuma alteração. Altere sua reserva ou saia da alteração',
          btn: 'ALTERAR RESERVA',
        },
        confirmation: 'Você ainda deseja prosseguir com esta reserva?',
        estimatedTime: 'Selecionar o horário de chegada estimado',
        availabilityUnknown: {
          title: 'A disponibilidade é desconhecida',
          description:
            'Desculpe, mas a capacidade para o horário selecionado é desconhecida.',
          solutions: {
            title: 'Você pode',
            points: [
              'alterar o número de convidados',
              'alterar os horários',
              'encontrar outra sala VIP',
            ],
          },
          endText: 'Ou tentar novamente mais tarde',
        },
        btn: {
          edit: 'Editar reserva',
          return: 'Retornar às salas VIP',
        },
      },
    },
    declineAmend: {
      title:
        'Infelizmente, essa reserva não pode ser alterada dentro de 48 horas do horário de chegada.',
    },
    confirmBooking: {
      title: 'Resumo da reserva',
      amendTitle: 'Resumo das alterações da reserva',
      text: 'Confirme se os dados estão corretos antes de fazer o pagamento.',
      btn: 'Ir para pagamento',
      loadingModal: {
        title: 'A página está carregando',
        description:
          'Aguarde enquanto a página está carregando. Isso pode levar alguns segundos para concluir. Agradecemos a sua paciência',
      },
    },
    entitlements: {
      title: 'Benefícios da pré-reserva',
      infoModal: {
        title: 'Benefícios da pré-reserva',
        text: 'Os benefícios da pré-reserva estão sendo fornecidos aos titulares de cartão Platinum e Centurion no Reino Unido, cortesia da American Express. Esses benefícios permitem que os titulares de cartão façam a pré-reserva em salas VIP Priority Pass elegíveis no Reino Unido. Ao fazerem a pré-reserva em uma sala VIP elegível, titulares de cartão poderão usar esses benefícios de pré-reserva para fazer a reserva para si mesmos e seus convidados.',
        btnClose: 'FECHAR',
      },
      countAndExpiryDate:
        'Você tem {count} benefícios de pré-reserva restantes, válidos até {date}',
      inputLabel:
        'Selecione o número de benefícios que você gostaria de usar para esta reserva',
    },
    payment: {
      amendmentPaymentPolicy:
        'Você pagará o valor integral pela alteração da sua reserva existente e será reembolsado com o valor total da reserva original',
      btnGoPayment: 'Ir para pagamento',
      title: 'Dados do pagamento',
    },
    confirmationPayment: {
      title: 'Confirmação da reserva',
      amendTitle: 'Confirmação da alteração da reserva',
      processing: {
        beingProcessed: {
          title: 'O pagamento está sendo processado',
          description: {
            line1: 'Seu pagamento para ',
            line2: 'está sendo processado.',
            line3:
              'Não atualize a página, pode levar alguns minutos para concluir.',
          },
        },
        unsuccessful: {
          title: 'O pagamento falhou',
          description:
            'Não saia desta tela, nem feche o navegador até que a ação termine ',
        },
      },
      outcome: {
        succesful: {
          title: 'Boa notícia! Sua reserva foi confirmada',
          titleAmend: 'Boa notícia! Sua reserva foi alterada',
          refundExplainedLine1:
            'Você será reembolsado com o valor integral da reserva original.',
          refundExplainedLine2:
            'Seu reembolso será processado no cartão bancário usado para pagar a reserva original dentro de 10 dias úteis.',
          refundText:
            ' Você reduziu o número de convidados na sua reserva. Seu reembolso será processado no cartão bancário usado para pagar a reserva original dentro de 10 dias úteis.',
          reference: {
            label: 'Número da reserva',
          },
          emailConfirmationLabel: 'Um e-mail de confirmação foi enviado para',
          entitlementsRedeemed: 'Benefício{plural} resgatado{plural}',
          entitlementsRemaining:
            'Você tem {count} benefícios de pré-reserva restantes.',
          description:
            'Pedimos desculpas, mas ocorreu um erro durante o processo de pagamento e seu pagamento não foi processado. Solicitamos que você faça uma nova reserva ou repita o pagamento.',
          importantNotes: {
            title: 'Observações importantes',
            notes: {
              one: 'Lembre-se de trazer o número da sua reserva, cartão de embarque e documento de identificação com foto, além do cartão de associação {membershipType} ou método de acesso válido para fazer check-in na sala VIP.',
              two: 'A permanência máxima é de 3 horas antes do horário do seu voo.',
              three:
                'O cancelamento deve ser feito no mínimo 48 horas antes da data e hora de sua visita para receber um reembolso. Nenhum reembolso será emitido após esse período.',
            },
            amendedNote:
              'Você foi cobrado o valor integral pela alteração da sua reserva existente. O valor integral da reserva original será reembolsado no cartão bancário usado para pagar a reserva original dentro de 10 dias úteis.',
          },
          btn: {
            download: 'BAIXAR CONFIRMAÇÃO DA RESERVA',
            return: 'Retornar à sala VIP',
          },
        },
        notConfirmed: {
          title: 'Seu pagamento não foi confirmado',
          description:
            'Pedimos desculpas, mas ocorreu um erro durante o processo de pagamento e seu pagamento não foi processado. Solicitamos que você faça uma nova reserva ou repita o pagamento.',
          btn: {
            payment: 'Repetir pagamento',
            return: 'Retornar à sala VIP',
          },
        },
        delay: {
          title: 'Atraso na confirmação da reserva',
          description:
            'Ainda não foi possível confirmar sua reserva; nós lhe enviaremos um e-mail assim que a sua reserva for confirmada',
          btn: 'Retornar às salas VIP',
        },
        delayError: {
          title: 'Nenhuma confirmação recebida',
          description:
            'Ainda não foi possível confirmar sua reserva; nós lhe enviaremos um e-mail assim que a sua reserva for confirmada',
          btn: 'Retornar às salas VIP',
        },
        declined: {
          title: 'Sua reserva não foi aceita',
          description:
            'Pedimos desculpas pelo atraso no processamento. Não foi possível confirmar sua reserva. Considere reservar outro horário.',
          btn: {
            return: 'Retornar às salas VIP',
            select: 'Selecione outro horário',
          },
        },
      },
    },
    cancellation: {
      title: 'Cancelamento da reserva',
      description: 'Você está prestes a cancelar a reserva: tem certeza?',
      reference: 'Número da reserva:',
      btnCancel: 'CANCELAR RESERVA',
      btnClose: 'FECHAR',
      policy: {
        title: 'Política de cancelamento',
        description:
          'Cancele até 48 horas antes da sua reserva para receber o reembolso integral. As reservas não podem ser canceladas dentro de 48 horas do horário de chegada, incluindo novas reservas feitas dentro desse intervalo de tempo.',
      },
      unsuccesful:
        'Infelizmente, essa reserva não pode ser cancelada dentro de 48 horas do horário de chegada',
      btn: 'Confirmar',
      errors: {
        notFound: 'Não foi possível encontrar a reserva',
        alreadyCancelled: 'A reserva já foi cancelada',
        notOwned:
          'Ocorreu um erro em sua reserva. Tente novamente mais tarde ou entre em contato com o suporte',
        failed:
          'Ocorreu um erro em sua reserva. Tente novamente mais tarde ou entre em contato com o suporte',
        notAllowed:
          'Infelizmente, essa reserva não pode ser cancelada dentro de 48 horas do horário de chegada.',
        wrong:
          'Ocorreu um erro em sua reserva. Tente novamente mais tarde ou entre em contato com o suporte',
      },
      confirmation: {
        title: {
          Cancel: 'Sua reserva foi cancelada',
          Failed:
            'O cancelamento da sua reserva falhou, entre em contato com a nossa equipe',
          NotCancel:
            'Não foi possível cancelar a sua reserva, entre em contato com a nossa equipe',
        },
        email: 'Um e-mail de confirmação foi enviado para',
        refund: {
          failed:
            'Tentativa de reembolso falhou. Entre em contato com o seu banco ou com o emissor do cartão para obter ajuda.',
          successful:
            ' Seu pagamento para esta reserva será reembolsado dentro de 10 dias',
        },
        entitlementsRefund:
          'Quaisquer benefícios de pré-reserva usados na sua reserva estarão disponíveis novamente em breve.',
        entitlementsExpiration:
          'Observe que seus benefícios existentes perderão a validade em {date}',
        reBook:
          'Se você não pretendia cancelar, reserve novamente pelo {accountProvider}. Esperamos vê-lo novamente.',
        moreInformation:
          'Para mais informações, acesse nossa página de {faq:perguntas frequentes}',
        loading: 'Carregando...',
      },
    },
    checkAvailability: {
      arrivalTitle: 'Seleção do horário de chegada',
      amendTitle: 'Alterações na reserva',
      notFoundError: 'Ocorreu um erro. Tente novamente',
      btn: 'VERIFICAR A DISPONIBILIDADE',
    },
    failureBooking: {
      declined: {
        title: 'Sua reserva não foi aceita.',
        description:
          'Infelizmente, durante o processo de pagamento, a capacidade da sala VIP foi alterada e não foi mais possível confirmar sua reserva. Você será reembolsado por qualquer pagamento feito.',
        note: 'Considere reservar outro horário, ou veja se há outra sala VIP disponível.',
        btn: {
          selectAnotherTime: 'SELECIONE OUTRO HORÁRIO',
          returnToLounge: 'Retornar às salas VIP',
        },
      },
    },
    confirmationPDF: {
      title: 'Confirmação da reserva',
      description: {
        line1: 'Boa notícia! Sua reserva para',
        line2: 'em',
        line3: 'foi confirmada.',
      },
      bookingDetails: {
        title: 'Dados da sua reserva',
        reference: 'Número da reserva:',
        date: 'Data:',
        flightNumber: 'Número do voo:',
        timeOfArrival: 'Horário estimado de chegada:',
      },
      guestDetails: {
        title: 'Quem vem?',
        adults: 'Adultos',
        children: 'Crianças',
        infants: 'Bebês',
      },
      entitlements: {
        redeemed: 'Benefício de pré-reserva resgatado:',
        remaining: 'Benefícios de pré-reserva restantes:',
        validUntil: 'Os benefícios de pré-reserva são válidos até:',
      },
      price: 'Total',
      importantNotes: {
        title: 'Observações importantes',
        checkInNote:
          'Lembre-se de trazer o número da sua reserva, cartão de embarque e documento de identificação com foto, além do cartão de associação {membershipType} ou método de acesso válido para fazer check-in na sala VIP.',
        maximumStay:
          'A permanência máxima é de 3 horas antes do horário do seu voo.',
        cancelText:
          'O cancelamento deve ser feito no mínimo 48 horas antes da data e hora de sua visita para receber um reembolso. Nenhum reembolso será emitido após esse período. ',
        amendText: 'Por favor, clique no link se você deseja ',
      },
      amendLink: 'Editar a reserva',
      cancelLink: 'Clique aqui para cancelar sua reserva',
      forwardText: 'Esperamos você por lá!',
      loadingText: 'Carregando documento…',
      error:
        'Ocorreu um erro ao gerar a confirmação da sua reserva! Por favor, tente novamente.',
    },
    failureAmendmentRefund: {
      title: 'Suas alterações de reserva foram recusadas',
      description:
        'Desculpe, a disponibilidade do lounge mudou enquanto seu pedido estava sendo processado e não foi possível confirmar a edição da reserva. Sua reserva permanece igual. Se ainda quiser editar sua reserva, por favor, tente novamente',
    },
  },
  generic: {
    error: {
      latestAppVersion:
        'Pode ter ocorrido um erro no sistema. Atualize para a versão mais recente do aplicativo.',
      webTitle:
        'Pode ter ocorrido um erro no sistema. Tente novamente ou pesquise outras opções.',
      loungeNoFound:
        'Desculpe, não conseguimos encontrar o quarto que você solicitou.',
      tokenNoFound: 'Desculpe, token não encontrado',
    },
    branding: {
      MCAECustomBrandName: 'Mastercard Airport Experiences',
    },
  },
};
